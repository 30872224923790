import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { injectIntl, defineMessages } from 'react-intl';

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

import {
  PAYPAL,
  ADYEN,
  AMAZON,
  FRESHDESK_PAYPAL_CANCELLED_MSG,
} from '../../../utils/variables';
import { NotificationDialogContext } from '../../context/NotificationDialogContext';

import CheckoutBillTable from './tables/CheckoutBillTable';
import CheckoutOrderingPaymentStepPaymentMethods from './CheckoutOrderingPaymentStepPaymentMethods';
import CheckoutOrderingPaymentStepAddress from './CheckoutOrderingPaymentStepAddress';
import CheckoutOrderingPaymentStepCheckbox from './CheckoutOrderingPaymentStepCheckbox';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { customScrollToComponent } from '../../../utils/common';
import { PaymentNotificationDialog } from '../dialogs/PaymentNotificationDialog';

import {
  BraintreeClientTokenMutation,
  AdyenPaymentMethodsMutation,
  AmazonPayPayloadMutation,
  PayWithAdyenMutation,
  SetOrderPaymentMethodMutation,
  AdyenPaymentsDetailsMutation,
} from '../../../mutations';

import { initPaypalButton } from '../../../utils/initPaypalButton';
import initAdyenComponent from '../../../utils/initAdyenComponent';
import initAmazonComponent from '../../../utils/initAmazonComponent';
import {
  getSelectedPaymentMethod,
  includesPayPal,
  includesAdyen,
  includesAmazon,
  getRealAmount,
  includesPhotoDvd,
} from '../../../utils/common';
import { trackEvent } from '../../../utils/ga-tracking';
import { sendError } from '../../../appsignal';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';

/////////////////////////////////////
// TODO missing test cases
// lines ...,105-118,125-126,150,159-160,165,169,174,247-252,259-267,275-283,291-311,317-341,347-357,364-381,388-397,486
/////////////////////////////////////

const CheckoutOrderingPaymentStep = (props) => {
  const {
    cart,
    isPaypalExpress,
    availablePaymentMethods,
    onChangeAddress,
    onPreviousStep,
    couponRemovedDuringPayment,
    onToggleDvdDialog,
  } = props;

  const { order } = cart;
  const hasPaypal = includesPayPal(availablePaymentMethods);
  const hasAdyen = includesAdyen(availablePaymentMethods);
  const hasAmazon = includesAmazon(availablePaymentMethods);

  const notificationDialog = useContext(NotificationDialogContext);

  const paymentButtonDiv = useRef();
  const adyenCheckout = useRef();
  const amazonPayButton = useRef();

  const [acceptsPrivacy, setAcceptsPrivacy] = useState(false);
  const [acceptsSgContract, setAcceptsSgContract] = useState(false);
  const [addressCollapsed, setAddressCollapsed] = useState(true);
  const [errorMsgId, setErrorMsgId] = useState('paymentMethodNotDefinedError');
  const [isLoadingPayPalCheckout, setIsLoadingPayPalCheckout] = useState(false);
  const [isLoadingAdyenCheckout, setIsLoadingAdyenCheckout] = useState(false);
  const [isLoadingAmazonCheckout, setIsLoadingAmazonCheckout] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(
    getSelectedPaymentMethod(availablePaymentMethods, order)
  );
  const [paymentRequired, setPaymentRequired] = useState(props.paymentRequired);
  const [braintreeToken, setBraintreeToken] = useState(undefined);
  const [amazonPayload, setAmazonPayload] = useState(undefined);
  const [adyenPaymentMethods, setAdyenPaymentMethods] = useState(undefined);
  const [showTooltip, setShowTooltip] = useState(false);
  const [klarnaWarning, setKlarnaWarning] = useState(false);
  const [showPaymentError, setShowPaymentError] = useState(false);
  const [showCheckoutError, setShowCheckoutError] = useState(true);
  const [checkoutErrorPath, setCheckoutErrorPath] = useState('Checkout');
  const [couponFormRef, setCouponFormRef] = useState(null);

  const hasAdditionalDvd = useMemo(() => {
    return includesPhotoDvd(cart.additionalProducts);
  }, [cart.additionalProducts]);

  useEffect(() => {
    couponFormRef && customScrollToComponent(couponFormRef.current);
  }, [couponFormRef]);

  useEffect(() => {
    if (paymentMethod && paymentMethod.defaultPaymentType === PAYPAL) {
      if (braintreeToken) {
        renderPayPalCheckoutBtn();
      } else if (!isLoadingPayPalCheckout) {
        loadBraintreeToken();
      }
    } else if (paymentMethod && paymentMethod.defaultPaymentType === ADYEN) {
      if (adyenPaymentMethods) {
        renderAdyenCheckoutComponent();
      } else if (!isLoadingAdyenCheckout) {
        loadAdyenPaymentMethods();
      }
    } else if (paymentMethod && paymentMethod.defaultPaymentType === AMAZON) {
      if (amazonPayload) {
        renderAmazonCheckoutComponent();
      } else if (!isLoadingAmazonCheckout) {
        loadAmazonPayload();
      }
    }
  }, [paymentMethod, braintreeToken, adyenPaymentMethods, amazonPayload]);

  useEffect(() => {
    if (
      paymentMethod &&
      paymentMethod.name === 'SofortBanking' &&
      order.address.country.alpha2 === 'AT' &&
      paymentRequired
    ) {
      setKlarnaWarning(true);
    } else {
      setKlarnaWarning(false);
    }
  }, [paymentMethod, paymentRequired, couponRemovedDuringPayment]);


  const liftUpRefForCouponForm = (formRefPosition) => {
    setCouponFormRef(formRefPosition);
  };

  const handlePaymentMethodChanged = (pm) => {
    const { cart, relay } = props;
    setShowTooltip(false);
    setShowPaymentError(false);
    setPaymentMethod(pm);

    // We fire and forget this mutation just to have
    // the chance to know about the selected method earlier.
    SetOrderPaymentMethodMutation.commit(
      relay.environment,
      cart.order.id,
      pm.id,
      (error, updateOrder) => {
        if (error) {
          sendError(error);
        }
      }
    );
  };

  const handleCouponChanged = (isPaymentRequired) => {
    setShowTooltip(false);
    setPaymentRequired(isPaymentRequired);
  };

  const handleCouponErrorClose = () => {
    setShowCheckoutError(false);
    setCheckoutErrorPath(null);
  };

  const handlePrivacyCheckboxChanged = (event, isInputChecked) => {
    setShowTooltip(false);
    setAcceptsPrivacy(isInputChecked);
  };

  const handleSgContractCheckboxChanged = (event, isInputChecked) => {
    setShowTooltip(false);
    setAcceptsSgContract(isInputChecked);
  };

  const handleCompleteCheckout = (type) => {
    if (paymentRequired && !paymentMethod) {
      setShowTooltip(true);
      setErrorMsgId('paymentMethodNotDefinedError');
    } else if (!acceptsPrivacy) {
      setShowTooltip(true);
      setErrorMsgId('privacyCheckboxNotCheckedError');
    } else if (!acceptsSgContract) {
      setShowTooltip(true);
      setErrorMsgId('sgContractCheckboxNotCheckedError');
    } else if (type === 'pay' && paymentMethod.defaultPaymentType == AMAZON) {
      amazonPayButton.current.initCheckout({
        createCheckoutSessionConfig: {
          publicKeyId: amazonPayload.publicKeyId,
          payloadJSON: amazonPayload.payload,
          signature: amazonPayload.signature,
        },
      });
    } else if (type === 'pay') {
      props.onNextStep(paymentMethod);
    } else if (type === 'free') {
      props.onConfirmCheckout();
    }
  };

  const handleCloseTooltip = () => {
    setShowTooltip(false);
  };

  const handleShowAddress = () => {
    setAddressCollapsed(!addressCollapsed);
  };

  const shouldShowPaymentBtn = () => {
    if (!paymentRequired) {
      return false;
    }

    if (
      acceptsPrivacy &&
      acceptsSgContract &&
      !props.isPaypalExpress &&
      paymentMethod &&
      (paymentMethod.defaultPaymentType === PAYPAL ||
        paymentMethod.defaultPaymentType === ADYEN ||
        (paymentMethod.defaultPaymentType === AMAZON && isLoadingAmazonCheckout))
    ) {
      return false;
    }

    return true;
  };

  const getPayButtonClassName = () => {
    if (acceptsPrivacy && acceptsSgContract && (paymentMethod || !paymentRequired)) {
      return 'primary';
    }

    // default when payment can't be finished
    return 'primary disabled';
  };

  const getPaypalButtonClassName = () => {
    if (
      acceptsPrivacy &&
      acceptsSgContract &&
      !props.isPaypalExpress &&
      paymentMethod &&
      paymentRequired &&
      paymentMethod.defaultPaymentType === PAYPAL &&
      !isLoadingPayPalCheckout
    ) {
      return '';
    }

    // default class
    return 'paypal-checkout-hidden';
  };

  const getAdyenComponentClassName = () => {
    if (
      acceptsPrivacy &&
      acceptsSgContract &&
      !props.isPaypalExpress &&
      paymentMethod &&
      paymentRequired &&
      paymentMethod.defaultPaymentType === ADYEN
    ) {
      return '';
    }

    // default class
    return 'adyen-checkout-hidden';
  };

  const loadBraintreeToken = () => {
    const { relay } = props;
    if (!braintreeToken) {
      setIsLoadingPayPalCheckout(true);
      BraintreeClientTokenMutation.commit(relay.environment, (error, token) => {
        if (!error) {
          setBraintreeToken(token);
        }
      });
    }
  };

  const loadAdyenPaymentMethods = () => {
    const { cart, relay } = props;
    if (!adyenPaymentMethods) {
      setIsLoadingAdyenCheckout(true);
      AdyenPaymentMethodsMutation.commit(
        relay.environment,
        cart.order.id,
        (error, result) => {
          if (!error) {
            setAdyenPaymentMethods(result);
          }
        }
      );
    }
  };

  const loadAmazonPayload = () => {
    const { cart, relay } = props;
    if (!amazonPayload) {
      setIsLoadingAmazonCheckout(true);
      AmazonPayPayloadMutation.commit(
        relay.environment,
        cart.order.id,
        (error, result) => {
          if (!error) {
            setAmazonPayload(result);
          }
        }
      );
    }
  };

  const renderPayPalCheckoutBtn = () => {
    const { cart, onNextStep, intl } = props;

    setIsLoadingPayPalCheckout(true);

    initPaypalButton('paypal-checkout-btn', braintreeToken, {
      checkoutStep: 2,
      paymentMethod: paymentMethod,
      cart: cart,
      variables: {
        amount: getRealAmount(cart.order.bill.totalBruttoPriceUnpayed),
        currency: cart.totalPrice.currency,
      },
      locale: intl.locale,
      onClick: () => paymentButtonDiv.current.scrollIntoView({ behavior: 'smooth' }),
      onAuthorize: (nonce) => onNextStep(paymentMethod, nonce),
      onCancel: () => {
        notificationDialog.onOpenNotificationDialog(FRESHDESK_PAYPAL_CANCELLED_MSG);
        trackEvent('Checkout', 'Canceled payment', 'PayPal payment canceled');
      },
      onReady: () => {
        setIsLoadingPayPalCheckout(false);
      },
    });
  };

  const renderAdyenCheckoutComponent = () => {
    const { onNextStep, intl } = props;

    setIsLoadingAdyenCheckout(true);

    initAdyenComponent('#adyen-checkout-component', adyenPaymentMethods, {
      paymentMethod: paymentMethod,
      locale: intl.locale,
      address: order.address,
      onComplete: () => {
        setShowPaymentError(false);
        onNextStep(paymentMethod);
      },
      onSubmit: (data, component) => {
        handleAdyenSubmit(data, component);
      },
      onAdditionalDetails: (state, component) => {
        handleAdyenAdditionalDetails(state.data, component);
      },
      onError: (error, component) => {
        component.setStatus('ready');
        setShowPaymentError(true);
      },
      onReady: (adyen) => {
        adyenCheckout.current = adyen;
        setIsLoadingAdyenCheckout(false);
      },
    });
  };

  const renderAmazonCheckoutComponent = () => {
    const { cart, intl } = props;

    if (amazonPayButton.current === undefined) {
      setIsLoadingAmazonCheckout(true);

      initAmazonComponent('#amazon-checkout-component', {
        currency: cart.totalPrice.currency,
        locale: intl.locale,
        onReady: (component) => {
          amazonPayButton.current = component;
          setIsLoadingAmazonCheckout(false);
        },
      });
    }
  };

  const handleAdyenSubmit = (data, component) => {
    component.setStatus('loading');
    const { cart, relay, onNextStep } = props;
    PayWithAdyenMutation.commit(
      relay.environment,
      cart.order.id,
      JSON.stringify(data),
      (error, result) => {
        component.setStatus('ready');
        if (error) {
          sendError(error);
        } else if (result.action) {
          const action = JSON.parse(result.action);
          const configuration = {
            challengeWindowSize: '05',
          };
          adyenCheckout.current
            .createFromAction(action, configuration)
            .mount('#adyen-checkout-component');
        } else {
          onNextStep(paymentMethod, result.merchantReference, result.resultCode);
        }
      }
    );
  };

  const handleAdyenAdditionalDetails = (data, component) => {
    const { relay, onNextStep } = props;
    AdyenPaymentsDetailsMutation.commit(
      relay.environment,
      JSON.stringify(data.details),
      (error, result) => {
        component.setStatus('ready');
        if (error) {
          sendError(error);
        } else {
          onNextStep(paymentMethod, result.merchantReference, result.resultCode);
        }
      }
    );
  };

  const hasPaymentBtn = shouldShowPaymentBtn();
  return (
    <div className="order-payment-pay-step">
      {!addressCollapsed && (
        <CheckoutOrderingPaymentStepAddress
          address={order.address}
          onChangeAddress={onChangeAddress}
          onShowAddress={handleShowAddress}
        />
      )}
      <div className="row">
        <div className="col-xs-8 col-sm-3 col-sm-offset-3">
          <h2 className="order-payment-form-overview">
            <FormattedMessageWrappedInSpan
              id="checkoutOrderingPaymentStep.paymentBillTitle"
              defaultMessage="Bill overview"
            />
          </h2>
        </div>
        <div className="col-xs-8 col-sm-2 col-sm-offset-5">
          <div className="order-payment-form-address-expand" onClick={handleShowAddress}>
            {addressCollapsed && (
              <FormattedMessageWrappedInSpan
                id="checkoutOrderingPaymentStep.showAddressButton"
                defaultMessage="Show Address"
              />
            )}
          </div>
        </div>
      </div>
      <CheckoutBillTable
        onToggleDvdDialog={
          includesPhotoDvd(order.bill.products) ? null : onToggleDvdDialog
        }
        orderId={order.id}
        bill={order.bill}
        onCouponChanged={handleCouponChanged}
        couponShouldBeRemoved={props.couponShouldBeRemoved}
        showDvdHint={hasAdditionalDvd}
        setCouponFormRef={liftUpRefForCouponForm}
      />
      {paymentRequired && (
        <CheckoutOrderingPaymentStepPaymentMethods
          availablePaymentMethods={availablePaymentMethods}
          isPaypalExpress={isPaypalExpress}
          selectedPaymentMethod={paymentMethod}
          onPaymentMethodChanged={handlePaymentMethodChanged}
          klarnaWarning={klarnaWarning}
        />
      )}
      <CheckoutOrderingPaymentStepCheckbox
        acceptsPrivacy={acceptsPrivacy}
        acceptsSgContract={acceptsSgContract}
        onPrivacyCheckboxChanged={handlePrivacyCheckboxChanged}
        onSgContractCheckboxChanged={handleSgContractCheckboxChanged}
      />
      <div className="row">
        <div
          className="order-payment-form-navigation col-xs-16 col-sm-10 col-sm-offset-3"
          ref={paymentButtonDiv}
        >
          {hasPaymentBtn && (
            <Tooltip
              title={props.intl.formatMessage(translations[errorMsgId])}
              open={showTooltip}
              onClose={handleCloseTooltip}
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <button
                className={getPayButtonClassName()}
                id="button-pay"
                onClick={() => handleCompleteCheckout('pay')}
              >
                {props.intl.formatMessage(translations.nextButton)}
              </button>
            </Tooltip>
          )}
          {!hasPaymentBtn && !paymentRequired && (
            <button
              className={getPayButtonClassName()}
              id="button-checkout"
              onClick={() => handleCompleteCheckout('free')}
            >
              <FormattedMessageWrappedInSpan
                id="checkoutOrderingPaymentStep.confirmFreeOrderButton"
                defaultMessage="Buy"
              />
            </button>
          )}
          {!hasPaymentBtn &&
            (isLoadingPayPalCheckout ||
              isLoadingAdyenCheckout ||
              isLoadingAmazonCheckout) && <CircularProgress size={15} thickness={4} />}
          {hasPaypal && (
            <div className={getPaypalButtonClassName()} id="paypal-checkout-btn" />
          )}
          {hasAdyen && !isLoadingAdyenCheckout && (
            <div className={getAdyenComponentClassName()} id="adyen-checkout-component" />
          )}
          {hasAmazon && <div id="amazon-checkout-component" />}
        </div>
      </div>
      {showPaymentError && (
        <div className="row">
          <div className="order-payment-form-submit-error col-xs-16 col-sm-6 col-sm-offset-5">
            <FormattedMessageWrappedInSpan
              id="checkoutOrderingPaymentStep.submitError"
              defaultMessage="Your payment was either cancelled or refused. Please check your payment information and try again or contact our support."
            />
          </div>
        </div>
      )}
      {couponRemovedDuringPayment && (
        <PaymentNotificationDialog
          open={showCheckoutError}
          onRequestClose={handleCouponErrorClose}
          path={checkoutErrorPath}
        />
      )}
      <div className="row">
        <div className="order-payment-form-navigation col-xs-16 col-sm-5 col-sm-offset-3">
          <div className="navigation-back" onClick={onPreviousStep}>
            <KeyboardBackspaceRoundedIcon />
            <FormattedMessageWrappedInSpan
              id="checkoutOrderingPaymentStep.backButton"
              defaultMessage="Back to address"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const translations = defineMessages({
  paymentMethodNotDefinedError: {
    id: 'checkoutOrderingPaymentStep.paymentMethodNotDefinedError',
    defaultMessage: 'Please select a payment method',
  },
  privacyCheckboxNotCheckedError: {
    id: 'checkoutOrderingPaymentStep.privacyCheckboxNotCheckedError',
    defaultMessage: 'You have to accept our aggreements',
  },
  sgContractCheckboxNotCheckedError: {
    id: 'checkoutOrderingPaymentStep.sgContractCheckboxNotCheckedError',
    defaultMessage: 'You have to accept our contract',
  },
  nextButton: {
    id: 'checkoutOrderingPaymentStep.nextButton',
    defaultMessage: 'Pay now',
  },
});

CheckoutOrderingPaymentStep.propTypes = {
  paymentRequired: PropTypes.bool,
  availablePaymentMethods: PropTypes.arrayOf(PropTypes.object),
  isPaypalExpress: PropTypes.bool,
  onChangeAddress: PropTypes.func,
  onConfirmCheckout: PropTypes.func,
  onNextStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  cart: PropTypes.object,
  intl: PropTypes.object,
  relay: PropTypes.object,
  couponRemovedDuringPayment: PropTypes.bool,
  couponShouldBeRemoved: PropTypes.bool,
  onPaymentRequiredChange: PropTypes.func,
  onPaymentPossibleChange: PropTypes.func,
  onShowPaymentButtonChange: PropTypes.func,
  onPaymentMethodChange: PropTypes.func,
  onToggleDvdDialog: PropTypes.func,
};

export { CheckoutOrderingPaymentStep };

export default createFragmentContainer(injectIntl(CheckoutOrderingPaymentStep), {
  cart: graphql`
    fragment CheckoutOrderingPaymentStep_cart on Cart {
      id
      availablePaymentMethods {
        id
        name
        defaultPaymentType
      }
      order {
        id
        address {
          firstName
          lastName
          line1
          line2
          postalCode
          city
          country {
            alpha2
          }
          state
          ...CheckoutOrderingPaymentStepAddress_address
        }
        bill {
          totalBruttoPrice
          totalBruttoPriceUnpayed
          ...CheckoutBillTable_bill
        }
        payment {
          paymentMethod {
            id
            name
            defaultPaymentType
          }
        }
        state
      }
      additionalProducts {
        id
        type
      }
      paymentRequired
      totalPrice
    }
  `,
});
