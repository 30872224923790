import React from 'react';
import { useHistory } from 'react-router-dom';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { Time } from '@carbon/pictograms-react';

import { FormattedMessageWrappedInSpan } from '../components/misc';

const ErrorPage = () => {
  const history = useHistory();

  return (
    <div className="container-960 container-page error-page">
      <div className="col-sm-10 col-xs-16 error-page_texts">
        <h1>
          <FormattedMessageWrappedInSpan
            id="errorPage.timeoutName"
            defaultMessage="Timeout error"
          />
        </h1>
        <h3>
          <FormattedMessageWrappedInSpan
            id="errorPage.message"
            defaultMessage="Sorry. We're experiencing heavy traffic. Try again in 10 minutes."
          />
        </h3>
        <button className="btn btn-default primary-new" onClick={() => history.goBack()}>
          <FormattedMessageWrappedInSpan
            id="errorPage.goBackButton"
            defaultMessage="Go back"
          />
        </button>
      </div>
      <div className="col-sm-6 col-xs-16 error-page_imgs">
        <Time className="error-page_img-clock" height="80%" width="80%" />
        <WarningRoundedIcon className="error-page_img-exclamation" />
      </div>
    </div>
  );
};

export { ErrorPage };
export default ErrorPage;
