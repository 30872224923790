import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FormattedMessageWrappedInSpan } from './components/misc';

import CookieBanner from './components/content/CookieBanner';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ScrollToTopOnMount from './components/misc/ScrollToTopOnMount';
import UnsupportedBrowserBanner from './components/content/UnsupportedBrowserBanner';
import withNotificationDialogConsumer from './components/hoc/withNotificationDialogConsumer';
import BackgroundSlideshow from './components/misc/BackgroundSlideshow';

import AccountPageQueryRenderer from './pages/AccountPageQueryRenderer';
import AccountEventPageQueryRenderer from './pages/AccountEventPageQueryRenderer';
import CartPageQueryRenderer from './pages/CartPageQueryRenderer';
import CheckoutPageQueryRenderer from './pages/CheckoutPageQueryRenderer';
import EventPageQueryRenderer from './pages/EventPageQueryRenderer';
import EventSnSearchPageQueryRenderer from './pages/EventSnSearchPageQueryRenderer';
import EventSmartSearchPageQueryRenderer from './pages/EventSmartSearchPageQueryRenderer';
import EventCategorySearchPageQueryRenderer from './pages/EventCategorySearchPageQueryRenderer';
import EventGpsSearchPageQueryRenderer from './pages/EventGpsSearchPageQueryRenderer';
import EventDualSearchPageQueryRenderer from './pages/EventDualSearchPageQueryRenderer';
import FeedbackDialog from './components/content/dialogs/FeedbackDialog';
import HomePageQueryRenderer from './pages/HomePageQueryRenderer';
import LoginPage from './pages/LoginPage';
import SubeventsPage from './pages/SubeventsPage';
import AccountRecheckPageQueryRenderer from './pages/AccountRecheckPageQueryRenderer';

import AboutPage from './pages/static/aboutPage/AboutPage';
import ContactPage from './pages/static/ContactPage';
import JobsPage from './pages/static/jobsPage/JobsPage';
import CookiesInfoPage from './pages/static/CookiesInfoPage';
import LegalPage from './pages/static/LegalPage';
import PrivacyPage from './pages/static/PrivacyPage';
import TermsAndConditionPage from './pages/static/TermsAndConditionPage';
import ErrorPage from './pages/ErrorPage';

import { isSupportedBrowser } from './utils/browser';
import { sendError } from './appsignal';

const FILE_MAINTAINANCE = 'maintainance';
const FILE_NOTIFICATION = 'notification';
const FILE_ERROR = 'error';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      maintainanceJson: null,
      errorJson: null,
      notificationJson: null,
      hideCookieBannerForThisSession: false,
      showFeedbackDialog: false,
      showBrowserUnsopportedBanner: !isSupportedBrowser(window),
    };

    this.handleOpenFeedbackDialog = this.handleOpenFeedbackDialog.bind(this);
    this.handleCloseFeedbackDialog = this.handleCloseFeedbackDialog.bind(this);
  }

  componentDidMount() {
    const fileNames = [FILE_MAINTAINANCE, FILE_NOTIFICATION, FILE_ERROR];
    const self = this;
    fileNames.forEach((fileName, index) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        if (this.status == 200) {
          const parsedJson = JSON.parse(this.responseText);
          if (parsedJson.active === 'true') {
            switch (fileName) {
              case 'maintainance':
                self.setState({ maintainanceJson: parsedJson });
                break;
              case 'notification':
                self.setState({ notificationJson: parsedJson }, () => {
                  const { notificationJson } = self.state;
                  const msg =
                    notificationJson[self.props.language] || notificationJson['en'];
                  self.props.onOpenNotificationDialogWithMessage(msg);
                });
                break;
              case 'error':
                self.setState({ errorJson: parsedJson });
                break;
            }
          }
        }
        if (index === fileNames.length - 1) {
          self.setState({ isLoading: false });
        }
      };
      xhr.open('GET', `/${fileName}.json`);
      xhr.send();
    });
  }

  componentDidCatch(error, info) {
    // ignore 'info' for the moment
    sendError(error);
  }

  handleOpenFeedbackDialog() {
    this.setState({ showFeedbackDialog: true });
  }

  handleCloseFeedbackDialog() {
    this.setState({ showFeedbackDialog: false });
  }

  _getMessageFromFile(fileName, language) {
    switch (fileName) {
      case FILE_MAINTAINANCE:
        return this.state.maintainanceJson[language] || this.state.maintainanceJson.en;
      case FILE_NOTIFICATION:
        return this.state.notificationJson[language] || this.state.notificationJson.en;
      case FILE_ERROR:
        return this.state.errorJson[language] || this.state.errorJson.en;
      default:
        return 'Very important work on our DB system';
    }
  }

  // This function is only use as a last resort to figure out where
  // the app should redirect the user depending on the current url
  getNextRedirectPath() {
    if (window) {
      if (window.location.pathname === '/') {
        return '/';
      }
      const pathnameSplitted = window.location.pathname.split('/');
      let nextPath = '';
      for (let i = 0; i < pathnameSplitted.length - 1; i++) {
        // this for loop will ignore the last element
        if (pathnameSplitted[i] !== '') {
          nextPath += `/${pathnameSplitted[i]}`;
        }
      }
      return nextPath;
    }
    return '/';
  }

  // Getting the right redirection link if the user is coming
  // from and old URL of the ancient original website
  getOldLinksRedirectPath(type, params) {
    const eventIdFromParams =
      params.id.indexOf('-') > 0
        ? params.id.substr(0, params.id.indexOf('-'))
        : params.id;
    const startNumberFromParams =
      params.startnumber.indexOf('-') > 0
        ? params.startnumber.substr(0, params.startnumber.indexOf('-'))
        : params.startnumber;

    if (type === 'shop') {
      return `/${params.language}/event/${eventIdFromParams}`;
    } else if (type === 'search') {
      return `/${params.language}/event/${eventIdFromParams}/search/${startNumberFromParams}`;
    } else if (type === 'other') {
      return `/${params.language}/event/${eventIdFromParams}/search/${startNumberFromParams}`;
    }
    this.getNextRedirectPath();
  }

  getOldSignInLinkRedirectPath() {
    window.location.href = 'https://archive.sportograf.com/account/signin';
    return null;
  }

  refreshPage() {
    window.location.reload(false);
  }

  render() {
    const {
      isLoading,
      maintainanceJson,
      errorJson,
      hideCookieBannerForThisSession,
      showFeedbackDialog,
      showBrowserUnsopportedBanner,
    } = this.state;

    const {
      statsCookies,
      socialCookies,
      sportografWebCookies,
      language,
      location,
      onCookiesChange,
    } = this.props;

    if (isLoading) {
      return null;
    }

    if (maintainanceJson !== null) {
      return (
        <div className="main-container">
          <div className="error-view-container">
            <h4>
              <span
                dangerouslySetInnerHTML={{
                  __html: this._getMessageFromFile(FILE_MAINTAINANCE, language),
                }}
              />
            </h4>
          </div>
        </div>
      );
    }

    return (
      <ScrollToTopOnMount>
        <div className="main-container landing-page-container">
          {errorJson && (
            <div className="error-banner-container">
              <span
                dangerouslySetInnerHTML={{
                  __html: errorJson[language] || errorJson['en'],
                }}
              />
            </div>
          )}
          <Header />
          {!isLoading && (
            <Fragment>
              <BackgroundSlideshow />
              <Switch>
                <Route
                  exact={true}
                  path="/:language"
                  render={(props) => <HomePageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/subevents"
                  render={(props) => <SubeventsPage {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id"
                  render={(props) => <EventPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/secondFactorRedirection/:startnumber"
                  render={(props) => <EventPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/:bestof(bestof)"
                  render={(props) => <EventPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/search/:startnumber/:customerKey"
                  render={(props) => <EventSnSearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/search/:startnumber"
                  render={(props) => <EventSnSearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/search"
                  render={(props) => <EventSnSearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/smartsearch/:startnumber"
                  render={(props) => <EventSmartSearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/category/:categoryId/:startnumber"
                  render={(props) => <EventCategorySearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/category/:categoryId"
                  render={(props) => <EventCategorySearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/gps/:fileId/:startnumber"
                  render={(props) => <EventGpsSearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/gps/:fileId"
                  render={(props) => <EventGpsSearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/dual/:searchstartnumber/:startnumber"
                  render={(props) => <EventDualSearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/event/:id/dual/:searchstartnumber"
                  render={(props) => <EventDualSearchPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/cart"
                  render={(props) => <CartPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/checkout"
                  render={(props) => <CheckoutPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/checkout/:callbackUrl(adyen-redirect|amazon-result|amazon-cancel)"
                  render={(props) => <CheckoutPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/login"
                  render={(props) => <LoginPage {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/about"
                  render={(props) => <AboutPage {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/timeout"
                  render={(props) => <ErrorPage {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/account"
                  render={(props) => <Redirect to="/account/events" />}
                />
                <Route
                  exact={true}
                  path="/:language/account/signin"
                  render={(props) => (
                    <Redirect to={this.getOldSignInLinkRedirectPath()} />
                  )}
                />
                <Route
                  exact={true}
                  path="/:language/account/:section"
                  render={(props) => <AccountPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/account/:section/invoice/:id"
                  render={(props) => <AccountPageQueryRenderer {...props} />}
                />
                <Route
                  exact={true}
                  path="/:language/recheck"
                  render={(props) => <AccountRecheckPageQueryRenderer {...props} />}
                />
                <Route
                  path="/:language/account/event/:id/:bibnumber"
                  render={(props) => <AccountEventPageQueryRenderer {...props} />}
                />
                <Route
                  path="/:language/account/recheck/:id"
                  render={(props) => <AccountRecheckPageQueryRenderer {...props} />}
                />
                <Route exact={true} path="/:language/jobs" component={JobsPage} />
                <Route
                  exact={true}
                  path="/:language/jobs/:position"
                  component={JobsPage}
                />
                <Route exact={true} path="/:language/contact" component={ContactPage} />
                <Route exact={true} path="/:language/legal" component={LegalPage} />
                <Route
                  exact={true}
                  path="/:language/agb"
                  component={TermsAndConditionPage}
                />
                <Route
                  exact={true}
                  path="/:language/service/general/privacy"
                  render={(props) => <Redirect to="/privacy" />}
                />
                <Route exact={true} path="/:language/privacy" component={PrivacyPage} />
                <Route
                  exact={true}
                  path="/:language/cookies-info"
                  component={CookiesInfoPage}
                />
                <Route
                  exact={true}
                  path="/:language/shop/event/:id"
                  render={(props) => (
                    <Redirect
                      to={this.getOldLinksRedirectPath('shop', props.match.params)}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/:language/shop/search/:id"
                  render={(props) => (
                    <Redirect
                      to={this.getOldLinksRedirectPath('shop', props.match.params)}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/:language/shop/search/:id/:startnumber"
                  render={(props) => (
                    <Redirect
                      to={this.getOldLinksRedirectPath('search', props.match.params)}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/:language/shop/search/:id/:startnumber/:other*"
                  render={(props) => (
                    <Redirect
                      to={this.getOldLinksRedirectPath('other', props.match.params)}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/:language/:landingpage"
                  render={(props) => <HomePageQueryRenderer {...props} />}
                />
                <Redirect to={this.getNextRedirectPath()} />
              </Switch>
            </Fragment>
          )}
          <Footer
            statsCookies={statsCookies}
            socialCookies={socialCookies}
            sportografWebCookies={sportografWebCookies}
            language={language}
            onCookiesChange={onCookiesChange}
          />
          <div id="portal-root-for-fixed-el"></div>
          <CookieBanner
            show={
              !statsCookies &&
              !socialCookies &&
              !sportografWebCookies &&
              !hideCookieBannerForThisSession
            }
            statsCookies={statsCookies}
            socialCookies={socialCookies}
            sportografWebCookies={sportografWebCookies}
            onCookiesChange={onCookiesChange}
            onRequestClose={(userAgrees) => {
              this.setState({ hideCookieBannerForThisSession: true });
              onCookiesChange({
                stats: userAgrees,
                social: userAgrees,
                sportografWeb: userAgrees,
              });
              if (userAgrees) {
                this.refreshPage();
              }
            }}
          />
          <UnsupportedBrowserBanner
            show={showBrowserUnsopportedBanner}
            onRequestClose={() => this.setState({ showBrowserUnsopportedBanner: false })}
          />
          <FeedbackDialog
            open={showFeedbackDialog}
            onRequestClose={this.handleCloseFeedbackDialog}
          />
          {true /* Feedback is hidden for now */ ||
            location.pathname.includes('checkout') ||
            location.pathname.includes('account') ||
            location.pathname.includes('about') || (
              <button className="feedback-button" onClick={this.handleOpenFeedbackDialog}>
                <FormattedMessageWrappedInSpan
                  id="app.feedbackButton"
                  defaultMessage="Feedback"
                />
              </button>
            )}
        </div>
      </ScrollToTopOnMount>
    );
  }
}

App.propTypes = {
  statsCookies: PropTypes.bool,
  socialCookies: PropTypes.bool,
  sportografWebCookies: PropTypes.bool,
  language: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  onOpenNotificationDialog: PropTypes.func,
  onCookiesChange: PropTypes.func,
};

export default withNotificationDialogConsumer(App);
