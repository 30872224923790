import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Dialog from '@material-ui/core/Dialog';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const CustomDialog = (props) => {
  const {
    children,
    fullWidth,
    hasFixedWidth,
    modal,
    open,
    onRequestClose,
    className,
    classes,
    hideCloseIcon,
  } = props;

  return (
    <Dialog
      classes={{
        container: 'custom-dialog-body',
        paper: classNames('custom-dialog-content', {
          'full-width': fullWidth,
          'has-fixed-width': hasFixedWidth,
          [className]: className,
        }),
        ...classes,
      }}
      disableEscapeKeyDown={modal}
      open={open}
      onClose={(event, reason) => {
        if (modal && reason === "backdropClick") {
          return;
        }
        onRequestClose();
      }}
    >
      {!hideCloseIcon && onRequestClose && (
        <div className="dialog-cancel-icon-container" onClick={() => onRequestClose()}>
          <ClearRoundedIcon />
        </div>
      )}
      {children}
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  autoScrollBodyContent: true,
  fullWidth: false,
  hasFixedWidth: true,
  modal: false,
  hideCloseIcon: false,
};

CustomDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  hasFixedWidth: PropTypes.bool,
  modal: PropTypes.bool,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  className: PropTypes.string,
  hideCloseIcon: PropTypes.bool,
};

export default CustomDialog;
