import React, { Fragment, useState, useContext, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import { useParams } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import Slider from 'react-slick';
import { Redirect } from 'react-router-dom';

import { UserContext } from '../components/context/UserContext'
import EventList from '../components/content/lists/EventList';
import LoadingView from '../components/content/LoadingView';
import JobHint from '../components/content/dialogs/JobHint'

import { JOBS_AMOUNT } from '../pages/static/jobsPage/JobsPage';

const HomePage = (props) => {
  const {viewer, intl} = props;

  const [eventSearchQuery, setEventSearchQuery] = useState('')
  const [isFiltered, setIsFiltered] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const { landingpage } = useParams();

  const { onMarathonFotoChange } = useContext(UserContext);

  useLayoutEffect(() => {
    //reset value to always show a SG header and footer
    onMarathonFotoChange(false);
  }, [onMarathonFotoChange]);


  const handleEventSearchInputChanged = (event) => {
    setEventSearchQuery(event.target.value);
    // If the input is cleared, we must refetch the events
    // w/o filter query (`$query`)
    if (event.target.value.length === 0) {
      _noFilterFetch();
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      _refetch();
    }
  }

  const _noFilterFetch = () => {
    setIsFiltered(true);
    setIsFetching(true);
    props.relay.refetch(
      { query: null },
      null,
      () => {
        setIsFiltered(false);
        setIsFetching(false);
      },
    );
  }

  const _refetch = () => {
    if (eventSearchQuery.length !== 0) {
      setIsFiltered(true);
      setIsFetching(true);
      props.relay.refetch(
        { query: eventSearchQuery },
        null,
        () => { 
          setIsFiltered(false);
          setIsFetching(false);
        },
      );
    }
  }

  if (viewer.landingpage === null) {
    return <Redirect to={`/`}/>
  }

  return (
    <Fragment>
      {viewer.landingpage && (
        <style type="text/css">{viewer.landingpage.css}</style>
      )}
      <div className="hero-container-outer">
        <Slider
          autoplay={true}
          arrows={false}
          dots={false}
          draggable={false}
          swipe={false}
          pauseOnHover={false}
          touchMove={false}
        >
          {props.viewer.config.frontendCarousel.map((url,index) => (
            <div className="homepage-slideshow-slide" key={index}>
              <img src={url} alt=""/>
            </div>
          ))}
        </Slider>
        <div className="homepage-slideshow-cover"></div>
        <div className="hero-container absolute-cover-center">
          <div className="hero-text container-full-width container-960">
            <div className="landing-page-logo"></div>
            <p>photography</p>
            <p>for the love of sport</p>
          </div>
          <div className="hero-search container-full-width container-960">
            <input
              className="hero-search-textfield"
              onChange={handleEventSearchInputChanged}
              onKeyPress={handleKeyPress}
              placeholder={intl.formatMessage(translations.eventSearchInputHint)}
              type="text"
              value={eventSearchQuery}
            />
          </div>
        </div>
      </div>
      {isFetching ? (
          <LoadingView />
      ) : (
        <EventList viewer={viewer} hasFilter={isFiltered ? true : false} landingpage={landingpage} />
      )}
      {!viewer.landingpage && JOBS_AMOUNT > 0 && <JobHint />}
    </Fragment>
  );
}

HomePage.propTypes = {
  intl: PropTypes.object,
  viewer: PropTypes.object,
  relay: PropTypes.object,
};

const translations = defineMessages({
  eventSearchInputHint: {
    id: 'homePage.eventSearchInputHint',
    defaultMessage: 'Search for event',
  },
});

export { HomePage };

export default createRefetchContainer(
  injectIntl(HomePage),
  {
    viewer: graphql`
      fragment HomePage_viewer on Viewer {
        config {
          frontendCarousel
        }
        landingpage @skip(if: $noLandingpage) {
          css
        }
        ...EventList_viewer
      }
    `,
  },
  graphql`
    query HomePageQuery($count: Int!, $cursor: String, $query: String, $landingpage: String, $noLandingpage: Boolean) {
      viewer(landingpage: $landingpage) {
        ...HomePage_viewer
      }
    }
  `,
);
