import React, { Fragment, useEffect, useState, useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';

import { CartBillItem, CartTotal, CartCouponItem } from '../../cart-items';

import { FOTO_FLAT, PHOTO_DVD, SINGLE_PHOTO } from '../../../../utils/variables';

////////////////////////////////////////////
// TODO missing tests for useEffect
////////////////////////////////////////////

function CheckoutBillTable(props) {
  const { orderId, bill, onToggleDvdDialog, showDvdHint, setCouponFormRef } = props;
  const [hasBoughtDvd, setHasBoughtDvd] = useState(false);
  const [totalBruttoPriceUnpayed, setTotalBruttoPriceUnpayed] = useState(
    bill.totalBruttoPriceUnpayed
  );

  const couponFormRef = useRef();

  useEffect(() => {
    if (couponFormRef && setCouponFormRef) {
      setCouponFormRef(couponFormRef);
    }
  }, [setCouponFormRef])

  // If the DVD is part of the bought items, the products are NOT only available as
  // download
  useEffect(() => {
    let _hasBoughtDvd = false;
    bill.items.forEach((item) => {
      if (item.lineItem.product.type === PHOTO_DVD) {
        _hasBoughtDvd = true;
      }
    });
    setHasBoughtDvd(_hasBoughtDvd);
  }, [bill.items]);

  const onCouponChanged = (cart) => {
    setTotalBruttoPriceUnpayed(cart.order.bill.totalBruttoPriceUnpayed);
    props.onCouponChanged(cart.paymentRequired);
  };

  const isDigitalProduct = (itemType) => {
    return itemType === FOTO_FLAT || itemType === SINGLE_PHOTO;
  }

  return (
    <Fragment>
      <div className="row ">
        <div className="col-xs-16 col-sm-10 col-sm-offset-3">
          <div className="checkout-bill-items-container">
            <div className="checkout-bill-items-list">
              {bill.items.map((item) => (
                <CartBillItem
                  onToggleDvdDialog={isDigitalProduct(item.lineItem.product.type)  && !hasBoughtDvd && showDvdHint ? onToggleDvdDialog : null}
                  type="billItem"
                  billItem={item}
                  key={item.id}
                />
              ))}
            </div>
            <div ref={couponFormRef}>
              <CartCouponItem
                orderId={orderId}
                bill={bill}
                onCouponChanged={onCouponChanged}
                couponShouldBeRemoved={props.couponShouldBeRemoved}
              />
            </div>
            <CartTotal
              deliveryCharges={bill.charges}
              totalPrice={totalBruttoPriceUnpayed}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

CheckoutBillTable.propTypes = {
  bill: PropTypes.object,
  couponShouldBeRemoved: PropTypes.bool,
  onToggleDvdDialog: PropTypes.func,
  onCouponChanged: PropTypes.func,
  orderId: PropTypes.string,
  showDvdHint: PropTypes.bool,
  setCouponFormRef: PropTypes.func,
};

export { CheckoutBillTable };

export default createFragmentContainer(CheckoutBillTable, {
  bill: graphql`
    fragment CheckoutBillTable_bill on Bill {
      charges
      items {
        id
        lineItem {
          product {
            type
          }
        }
        ...CartBillItem_billItem
      }
      coupon {
        vat
        brutto
        code
      }
      totalBruttoPrice
      totalBruttoPriceUnpayed
      totalNettoPrice
    }
  `,
});
