import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import { useIntl } from 'react-intl';

// mask for hh:mm:ss format
const durationMask = [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/];

// format plain input to string in format hh:mm:ss
const formatDuration = (inputValue) => {
  let hours = inputValue.slice(0, 2) || '00';
  let minutes = inputValue.slice(2, 4) || '00';
  let seconds = inputValue.slice(4, 6) || '00';

  hours = hours.padStart(2, '0');
  minutes = minutes.padEnd(2, '0');
  seconds = seconds.padEnd(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

const MINUTES_SECONDS_ERROR = 'required_less_than_60';

const DurationInput = ({ onChange, inputId, label, errorFromParent }) => {
  const [error, setError] = useState(errorFromParent);
  const [unformattedValue, setUnformattedValue] = useState();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (errorFromParent) {
      setError(errorFromParent)
    }
  }, [errorFromParent])

  const intl = useIntl();

  const getHelperMessage = () => {
    if (!Boolean(error) && !Boolean(errorFromParent) && isFocused) {
      return intl.formatMessage({
        id: 'durationInput.helperText.requiredFormat',
        defaultMessage: 'Enter duration as hh:mm:ss',
      });
    }
    if (Boolean(errorFromParent)) {
      return errorFromParent;
    }
    if (error === MINUTES_SECONDS_ERROR) {
      return intl.formatMessage({
        id: 'durationInput.helperText.minutesOrSecondsError',
        defaultMessage: 'Minutes and seconds must be between 00 and 59',
      });
    }
    return null;
  };

  const validateAndFormatInput = (e) => {
    const plainInput = e.target.value.replace(/\D/g, '');

    if (plainInput.length > 6) return;

    const minutes = parseInt(plainInput.slice(2, 4) || '0', 10);
    const seconds = parseInt(plainInput.slice(4, 6) || '0', 10);

    // validation for proper time format
    if ((minutes < 10 && minutes * 10 > 59) || (seconds < 10 && seconds > 5)) {
      setError(MINUTES_SECONDS_ERROR);
      return;
    } else {
      setError(null);
      setUnformattedValue(plainInput);
      onChange({
        target: {
          id: inputId,
          value: formatDuration(plainInput),
        },
      });
    }
  };

  return (
    <TextField
      label={label}
      value={unformattedValue}
      id={inputId}
      error={Boolean(error) || Boolean(errorFromParent)}
      onChange={validateAndFormatInput}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => setIsFocused(false)}
      placeholder="00:00:00"
      InputProps={{
        inputComponent: MaskedInput,
        inputProps: {
          mask: durationMask,
          unformattedValue,
        },
      }}
      helperText={getHelperMessage()}
    />
  );
};

DurationInput.propTypes = {
  onChange: PropTypes.func,
  inputId: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  errorFromParent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};

export default DurationInput;
