import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomDialog from './CustomDialog';
import { FormattedMessageWrappedInSpan } from '../../misc';
import { getLoginTokenFromPath } from '../../../utils/common';
import { sendError } from '../../../appsignal';

const ErrorDialog = (props) => {
  const { open, error, onRequestClose, onResendLink, newLinkSent, isSendingNewLink } =
    props;
  const [shouldResendLink, setShouldResendLink] = useState(false);
  const [token, setToken] = useState(null);

  const history = useHistory();

  const isCartTakenError = error && error.message.toLowerCase().includes('has already been taken');

  if (error) {
    return (
      <CustomDialog open={open} onRequestClose={onRequestClose}>
        <div className="notification-container">
          <DialogContent>
            <div className="notification-body">
              <h1>
                <FormattedMessageWrappedInSpan
                  id="errorDialog.title"
                  defaultMessage="Error"
                />
              </h1>
              <ErrorDialogBody
                error={error}
                setShouldResendLink={setShouldResendLink}
                setToken={setToken}
              />
            </div>
          </DialogContent>
          <DialogActions>
            {(shouldResendLink || isCartTakenError) && (
              <div className="notification-action two-buttons">
                <button className="accept" onClick={onRequestClose}>
                  <FormattedMessageWrappedInSpan
                    id="errorDialog.okButton"
                    defaultMessage="OK"
                  />
                </button>
                {newLinkSent && !isCartTakenError && (
                  <button className="disabled">
                    <FormattedMessageWrappedInSpan
                      id="errorDialog.infoTokenResent"
                      defaultMessage="New link sent"
                    />
                  </button>
                )}
                {!newLinkSent && !isCartTakenError && (
                  <button className="accept" onClick={() => onResendLink(token)}>
                    {isSendingNewLink ? (
                      <CircularProgress size={30} thickness={4} />
                    ) : (
                      <FormattedMessageWrappedInSpan
                        id="errorDialog.resendTokenRequest"
                        defaultMessage="Send new link"
                      />
                    )}
                  </button>
                )}
                {isCartTakenError && (
                  <button className="accept" onClick={() => history.push('/cart')}>
                    <FormattedMessageWrappedInSpan
                      id="errorDialog.goToCart"
                      defaultMessage="Go to cart"
                    />
                  </button>
                )}
              </div>
            )}
            {!shouldResendLink && !isCartTakenError && (
              <div className="notification-action">
                <button className="accept" onClick={onRequestClose}>
                  <FormattedMessageWrappedInSpan
                    id="errorDialog.okButton"
                    defaultMessage="OK"
                  />
                </button>
              </div>
            )}
          </DialogActions>
        </div>
      </CustomDialog>
    );
  }

  return null;
};

ErrorDialog.propTypes = {
  open: PropTypes.bool,
  error: PropTypes.object,
  onRequestClose: PropTypes.func,
  onResendLink: PropTypes.func,
  newLinkSent: PropTypes.bool,
  isSendingNewLink: PropTypes.bool,
};

const ErrorDialogBody = ({ error, setShouldResendLink, setToken }) => {
  const lowerCaseMsg = error.message.toLowerCase();

  if (lowerCaseMsg.includes('failed to fetch')) {
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.failedToFetch.title"
            defaultMessage="Trouble connecting to server. Try again"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.failedToFetch"
          defaultMessage="The server didn't respond. You may retry your request when the server comes back up."
        />
      </>
    );
  } else if (lowerCaseMsg.includes('login_token expired')) {
    setShouldResendLink(true);
    setToken(getLoginTokenFromPath(location.search));
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.loginTokenExpired.title"
            defaultMessage="Magic Link expired. Request a new one"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.loginTokenExpired"
          defaultMessage="The Magic Link you are trying to log in with is expired. Please request a new one."
        />
      </>
    );
  } else if (lowerCaseMsg.includes('login_token invalid')) {
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.loginTokenInvalid.title"
            defaultMessage="Invalid token. Retry or contact support"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.loginTokenInvalid"
          defaultMessage="The token is invalid. Please try to copy the text link from the email and paste it directly into the address bar. If the error still occurs, please contact our support."
        />
      </>
    );
  } else if (lowerCaseMsg.includes('send_failed')) {
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.failedToSendEmail.title"
            defaultMessage="Failed to send email. Retry"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.failedToSendEmail"
          defaultMessage="An error occurred while sending the email. Please try again later."
        />
      </>
    );
  } else if (lowerCaseMsg.includes('recipient invalid')) {
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.recipentInvalid.title"
            defaultMessage="Invalid email address. Check again"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.recipentInvalid"
          defaultMessage="This address seems to be invalid. Please double-check and ensure the email address is correct."
        />
      </>
    );
  } else if (lowerCaseMsg.includes('recipient blocked')) {
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.recipentBlocked.title"
            defaultMessage="Blocked address. Check spam"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.recipentBlocked"
          defaultMessage="This address seems to be unreachable, or your provider marked us as spam. To receive emails again, please ensure you have no message from info@sportograf.com in your spam folder and added info@sportograf.com to your address book. Afterwards, please contact our support team to reactivate your address."
        />
      </>
    );
  } else if (lowerCaseMsg.includes('has already been taken')) {
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.duplicateItemInCart.title"
            defaultMessage="Already added"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.duplicateItemInCart"
          defaultMessage="This item is already in your cart"
        />
      </>
    );
  } else if (lowerCaseMsg.includes('incompatible line item price currency')) {
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.differentCurrenciesInCart.title"
            defaultMessage="Currency mismatch in cart"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.differentCurrenciesInCart"
          defaultMessage="Your cart can not have different currencies"
        />
      </>
    );
  } else if (lowerCaseMsg.includes('no_face_found')) {
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.noFaceFound.title"
            defaultMessage="No visible face. Try another image"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.noFaceFound"
          defaultMessage="There is no big enough face in this image. Please upload a photo where your face can clearly be seen and is at least 25% in width or height of the image."
        />
      </>
    );
  } else if (lowerCaseMsg.includes('unauthenticated')) {
    // for old error message format
    return (
      <>
        <h2>
          <FormattedMessageWrappedInSpan
            id="errorDialogBody.loginTokenExpired.title"
            defaultMessage="Magic Link expired. Request a new one"
            className="mb-15"
          />
        </h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.loginTokenExpired"
          defaultMessage="The Magic Link you are trying to log in with is expired. Please request a new one."
        />
      </>
    );
  }

  // By default send the unknown error to Appsignal and show the user a default
  // message
  sendError(error);
  return (
    <>
      <h2>
        <FormattedMessageWrappedInSpan
          id="errorDialogBody.unknowError.title"
          defaultMessage="Something went wrong. Try again"
          className="mb-15"
        />
      </h2>
      <FormattedMessageWrappedInSpan
        id="errorDialogBody.unknowError"
        defaultMessage="An unknown error has occured"
      />
    </>
  );
};

ErrorDialogBody.propTypes = {
  error: PropTypes.object,
  setShouldResendLink: PropTypes.func,
  setToken: PropTypes.func,
};

export default ErrorDialog;
