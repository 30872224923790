import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import {
  CircularProgress,
  TextField,
  FormGroup,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { FormattedMessageWrappedInSpan } from '../../misc';
import DurationInput from '../../misc/DurationInput';

const ENTER_KEY = 'Enter';
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const BIBNUMBER = 'userBib';
const RACE_TIME = 'raceTime';
const RACE_CATEGORY = 'category';

const CertificateUserDataDownloadForm = (props) => {
  const {
    onDownloadCertificate,
    pendingRequests,
    bibnumber,
    failed,
    startNumbers,
    isFaceSearchEvent,
  } = props;

  const intl = useIntl();
  const errorsAndPlaceholdersTranslations = {
    requiredinput: intl.formatMessage({
      id: 'certificateUserDataDownloadForm.errors.emptyInput',
      defaultMessage: 'This field is required',
    }),
    tooLongInput: intl.formatMessage({
      id: 'certificateUserDataDownloadForm.errors.tooLongInput',
      defaultMessage: 'Field cannot exceed 25 characters',
    }),
    outcomeErrorText: intl.formatMessage({
      id: 'certificateUserDataDownloadForm.errors.outcomeErrorText',
      defaultMessage: 'Could not download. Have you given a correct bib number?',
    }),
    onlyAlphanumericAllowed: intl.formatMessage({
      id: 'certificateUserDataDownloadForm.errors.onlyAlphanumericAllowed',
      defaultMessage: 'Field can not contain special characters',
    }),
    firstNamePlaceholder: intl.formatMessage({
      id: 'certificateUserDataDownloadForm.fields.placeholder.firstName',
      defaultMessage: 'First name',
    }),
    lastNamePlaceholder: intl.formatMessage({
      id: 'certificateUserDataDownloadForm.fields.placeholder.lastName',
      defaultMessage: 'Last name',
    }),
    bibnumberPlaceholder: intl.formatMessage({
      id: 'certificateUserDataDownloadForm.fields.placeholder.bibNumber',
      defaultMessage: 'Bib number',
    }),
    categoryPlaceholder: intl.formatMessage({
      id: 'certificateUserDataDownloadForm.fields.placeholder.category',
      defaultMessage: 'Category',
    }),
  };

  const [formState, setFormState] = useState({
    fields: {
      firstName: '',
      lastName: '',
      raceTime: null,
      userBib: bibnumber && !isFaceSearchEvent ? bibnumber : '',
      category: '',
    },
    errors: {
      firstName: '',
      lastName: '',
      raceTime: '',
      userBib: failed ? errorsAndPlaceholdersTranslations.outcomeErrorText : '',
      category: '',
    },
  });

  useEffect(() => {
    if (failed) {
      setFormState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          userBib: errorsAndPlaceholdersTranslations.outcomeErrorText,
        },
      }));
    }
  }, [failed]);

  const isPending = () => {
    return pendingRequests && pendingRequests.length > 0;
  };

  const hasNoErrors = () => {
    return Object.values(formState.errors).every((error) => !Boolean(error));
  };

  const allFieldsFilled = () => {
    return Object.values(formState.fields).every((field) => Boolean(field));
  };

  const setEmptyFieldErrors = () => {
    const newErrors = {};
    Object.keys(formState.fields).forEach((field) => {
      const value = formState.fields[field];
      if (!Boolean(value)) {
        newErrors[field] = errorsAndPlaceholdersTranslations.requiredinput;
      } else {
        newErrors[field] = '';
      }
    });
    setFormState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        ...newErrors,
      },
    }));
  };

  const validateField = (value, id) => {
    const trimmedValue = value.trim();
    if (trimmedValue.length === 0) {
      return errorsAndPlaceholdersTranslations.requiredinput;
    }
    if (value.length > 25) {
      return errorsAndPlaceholdersTranslations.tooLongInput;
    }
    if (id != RACE_TIME && !(/^[\p{L}\p{N}\s]+$/u.test(trimmedValue))) {
      return errorsAndPlaceholdersTranslations.onlyAlphanumericAllowed;
    }
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    const error = validateField(value, id);
    // every error other than requiredinput (too long input, inproper char) type should prevent value change
    if (error && error != errorsAndPlaceholdersTranslations.requiredinput) {
      setFormState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [id]: error,
        },
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          [id]: value,
        },
        errors: {
          ...prevState.errors,
          [id]: error || '',
        },
      }));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === ENTER_KEY) {
      submitForm();
    }
  };

  const not_ready = () => {
    return isPending() || !allFieldsFilled() || !hasNoErrors();
  };

  const submitForm = () => {
    if (!allFieldsFilled()) {
      setEmptyFieldErrors();
      return;
    }
    if (!startNumbers.includes(formState.fields.userBib)) {
      setFormState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          userBib: errorsAndPlaceholdersTranslations.outcomeErrorText,
        },
      }));
      return;
    }
    if (not_ready()) {
      return;
    }
    onDownloadCertificate(
      formState.fields.userBib,
      formState.fields.category,
      formState.fields.firstName,
      formState.fields.lastName,
      formState.fields.raceTime
    );
  };

  return (
    <div className="notification-container">
      <DialogContent>
        <div className="notification-body">
          <h1>
            <FormattedMessageWrappedInSpan
              id="certificateUserDataDownloadForm.title"
              defaultMessage="Enter data required to generate your certificate"
              className="mb-15"
            />
          </h1>
          <FormGroup>
            <TextField
              autoComplete="off"
              className="notification-input"
              error={Boolean(formState.errors.firstName)}
              id={FIRST_NAME}
              helperText={formState.errors.firstName}
              label={
                <FormattedMessageWrappedInSpan
                  id="certificateUserDataDownloadForm.fields.label.firstName"
                  defaultMessage="Enter your first name"
                />
              }
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={errorsAndPlaceholdersTranslations.firstNamePlaceholder}
              value={formState.fields.firstName}
            />
            <TextField
              autoComplete="off"
              className="notification-input"
              error={Boolean(formState.errors.lastName)}
              id={LAST_NAME}
              helperText={formState.errors.lastName}
              label={
                <FormattedMessageWrappedInSpan
                  id="certificateUserDataDownloadForm.fields.label.lastName"
                  defaultMessage="Enter your last name"
                />
              }
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={errorsAndPlaceholdersTranslations.lastNamePlaceholder}
              value={formState.fields.lastName}
            />
            <TextField
              autoComplete="off"
              className="notification-input"
              error={Boolean(formState.errors.userBib)}
              id={BIBNUMBER}
              helperText={formState.errors.userBib}
              label={
                <FormattedMessageWrappedInSpan
                  id="certificateUserDataDownloadForm.fields.label.bibNumber"
                  defaultMessage="Enter one bib number"
                />
              }
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={errorsAndPlaceholdersTranslations.bibnumberPlaceholder}
              value={formState.fields.userBib}
            />
            <TextField
              autoComplete="off"
              className="notification-input"
              error={Boolean(formState.errors.category)}
              id={RACE_CATEGORY}
              helperText={formState.errors.category}
              label={
                <FormattedMessageWrappedInSpan
                  id="certificateUserDataDownloadForm.fields.label.category"
                  defaultMessage="Enter your category"
                />
              }
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={errorsAndPlaceholdersTranslations.categoryPlaceholder}
              value={formState.fields.category}
            />
            <DurationInput
              value={formState.fields.raceTime}
              inputId={RACE_TIME}
              onChange={handleInputChange}
              errorFromParent={formState.errors.raceTime}
              label={
                <FormattedMessageWrappedInSpan
                  id="certificateUserDataDownloadForm.fields.label.raceTime"
                  defaultMessage="Enter your result"
                />
              }
            />
          </FormGroup>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="notification-action with-spinner">
          <button
            className={classNames('accept', { disabled: not_ready() })}
            onClick={submitForm}
          >
            <FormattedMessageWrappedInSpan
              id="thumbnailPreviewAccountDetails.certificateDownloadForm.acceptButton"
              defaultMessage="Download"
            />
            {isPending() && (
              <div className="download-dropdown-expand-icon-container">
                <CircularProgress
                  classes={{ circle: 'circular-progress-circle-copy-color-medium' }}
                  size={20}
                  thickness={3}
                />
              </div>
            )}
          </button>
        </div>
      </DialogActions>
    </div>
  );
};

CertificateUserDataDownloadForm.propTypes = {
  onDownloadCertificate: PropTypes.func,
  pendingRequests: PropTypes.array,
  bibnumber: PropTypes.string,
  failed: PropTypes.bool,
  startNumbers: PropTypes.array,
  isFaceSearchEvent: PropTypes.bool,
};

export default CertificateUserDataDownloadForm;
export { CertificateUserDataDownloadForm };